.page-manifesto {
  max-width: 100vw;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;

  #main-element {
    padding-top: 280px;
    min-height: 100vh;

    @media screen and (max-width: 1024px) {
      padding-top: 145px;
    }
  }

  .color-band {
    position: fixed;
    top: calc(65% - 91px);
    left: -100%;
    width: 0;
    max-width: unset;
    height: 192px;
    opacity: 1;
    transition: width 0.64s ease-out;
    z-index: 0;

    &-first-anim {
      left: 0;
      width: 200vw;
      transition: width 1.5s ease-out;
    }
  }

  .manifesto-header {
    /* MANIFESTO */
    font-family: 'Josefin Sans', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.2em;
    color: $dark1;
    opacity: 0;
    margin: auto auto 20px 100px;

    &-first-anim {
      opacity: 1;
      transition: opacity 0.64s ease-out;
    }

    @media screen and (max-width: 1024px) {
      margin-left: 24px;
      font-weight: 900;
      font-size: 16px;
    }
  }

  .main-box {
    z-index: 1;
    background: $dark3;
    border-radius: 20px 20px 0px 0px;
    width: calc(100% - 200px);
    align-self: center;
    justify-self: right;
    display: flex;
    opacity: 0;
    padding: 80px 0 0 100px;
    min-height: calc(100vh - 390px);

    &-first-anim {
      opacity: 1;
      transition: all 0.64s ease-out;
    }

    .why-dream {
      width: 40%;
      opacity: 0;

      &-first-anim {
        opacity: 1;
        transition: opacity 0.64s ease-out;
      }

      &-inner-header {
        width: 100%;
      }

      &-inner-text {
        width: 100%;
      }

      h2 {
        font-family: 'Gore', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 100%;
        letter-spacing: 0.03em;
        color: $white;
      }

      p {
        font-family: 'Josefin Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 140%;
        color: $yellow3;
      }
    }

    .computer-image {
      width: 60%;
      display: flex;
      position: relative;

      .computer {
        position: absolute;
        width: 100%;
        top: -47%;
        right: -30px;
        opacity: 0;
        transition: all 0.64s ease-out;
        z-index: 0;
        max-width: 81vh;

        &-first-anim {
          bottom: 0;
          right: 0;
          opacity: 1;
          transition: all 0.64s ease-out;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 0;
      width: calc(100% - 48px);

      &-first-anim {
        padding: 50px 0 0 24px;
        width: calc(100% - 48px);
      }

      .why-dream {
        width: 100%;

        &-inner-header {
          width: 60%;
        }

        &-inner-text {
          width: 100%;
        }

        h2 {
          font-weight: 400;
          font-size: 30px;
        }

        p {
          font-weight: 400;
          font-size: 15px;
          line-height: 140%;
        }
      }

      .computer-image {
        width: 6%;
        display: flex;
        position: relative;

        .computer {
          position: absolute;
          width: 50vw;
          bottom: calc(100% - 13vh);
          right: -8vw;
          opacity: 0;
          transition: width 0.64s ease-out;
          z-index: 0;
          top: unset;

          &-first-anim {
            bottom: calc(100% - 13vh);
            right: -5vw;
            opacity: 1;
            transition: all 0.64s ease-out;
          }
        }
      }
    }
  }

  .footer-box-home {
    z-index: 2;
    opacity: 0;
    width: 100%;
    height: 50px;
    overflow: hidden;

    &-first-anim {
      opacity: 1;
      transition: all 0.64s ease-out;
    }

  }
}
