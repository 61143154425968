// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  overflow-x: hidden;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

//.fade-routing-enter {
//  opacity: 0;
//  z-index: 1;
//}
//.fade-routing-enter.fade-routing-enter-active {
//  opacity: 1;
//
//  transition: opacity 2s ease-out;
//}
//.fade-routing-exit {
//  opacity: 1;
//}
//.fade-routing-exit.fade-routing-exit-active {
//  opacity: 0;
//
//  transition: opacity 2000s ease-out;
//}

body {
  &.overlay-open {
    overflow: hidden;

    .team-overlay {
      transform: scale(1);
      opacity: 1;
      z-index: 999;
      pointer-events: auto;

      .cards {
        transform: scale(1);
        opacity: 1;
        transition-delay: 0s;
      }
    }
  }
}

//#main-wrapper {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//
//  position: absolute;
//  top: 0;
//  bottom: 0;
//  left: 0;
//  right: 0;
//}
//#main-wrapper * {
//  transition: background-color 2.5s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.8s ease-out, width 0.8s ease-out, all 0.8s ease-out;
//}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $light1;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $white;
  border-radius: 4px;
}

a {
  text-decoration: none;
  color: inherit;
}

strong {
  font-weight: 700;
}

img {
  display: block;
  max-width: 100%;
}

figure {
  display: block;
  margin: 0;
  padding: 0;
}

section {
  position: relative;
  z-index: 5;
}

.grid {
  display: grid;

  &.grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

//ul {
//    margin: 0;
//    padding: 0 0 0 50px;
//    list-style: disc outside none;
//
//    li {
//        margin-bottom: 30px;
//        padding-left: 50px;
//
//        &::marker {
//            color: $candyneon;
//        }
//
//        &:last-child {
//            margin: 0;
//        }
//    }
//}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;
}

.landing-journey {
  font-family: 'Gore', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.1vh;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.2em;
  color: #ffffff;
  margin-top: 50px;

  @media screen and (max-width: 450px) {
    font-size: 22px;
  }
}

.icons-row {
  display: flex;
  justify-content: center;
  align-content: center;
}

.icon-btn {
  display: flex;
  width: 4.5vh;
  height: 4.5vh;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  background-color: #f3ebdf;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-out;

  &:hover {
    box-shadow: 0 0 0 10px rgba(243, 235, 223, 0.2),
    0 0 0 20px rgba(243, 235, 223, 0.1);
  }
}

.landing-coming-soon {
  font-family: 'Josefin Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.2em;
  color: #ffffff;
}

.footer-box {
  bottom: 0;
  height: 50px;
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0 150px;

  @media screen and (max-width: 600px) {
    padding: 0 30px;
  }

  @media screen and (max-width: 450px) {
    padding: 0 20px;
    position: fixed;
  }
}

.planet-box {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  position: relative;
  justify-content: center;
  align-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90vh;

  @media screen and (max-width: 450px) {
    background-size: 833px;
    background-position: top;
    margin-top: -50px;
  }
}

.footer-text {
  font-family: 'Josefin Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $white;
  padding: 0;
  margin: 0;
  transition: transform 0.5s ease-out, filter 0.5s ease-out;

  &-standard {
    color: $dark3;
  }

  &-link {
    &:hover {
      color: #ff665c;
      font-weight: 700;
      //transform: scale(1.1);
    }
  }
}

.coming-fall-box {
  margin: auto;
  position: fixed;
  bottom: 10vh;

  @media screen and (max-width: 450px) {
    top: 72vh;
  }
}

.inside-planet-box {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 5vh;

  @media screen and (max-width: 450px) {
    align-self: flex-start;
    margin-top: 190px;
  }
}

.colour-band-box {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 20vh;

  @media screen and (max-width: 450px) {
    background-size: 0;
  }
}

.middle-logo {
  width: 25vh;

  @media screen and (max-width: 450px) {
    width: 230px;
  }
}
