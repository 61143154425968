// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
    font-family: 'Gore';
    src: url('./fonts/gore_regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Josefin Sans';
    src: url('./fonts/JosefinSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Josefin Sans Italic';
    src: url('./fonts/JosefinSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: auto;
}