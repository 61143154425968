.page-faq {
  max-width: 100vw;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;

  #main-element {
    padding-top: 280px;

    @media screen and (max-width: 1024px) {
      padding-top: 145px;
    }
  }

  .color-band {
    position: fixed;
    top: 625px;
    left: -100%;
    width: 0;
    max-width: unset;
    height: 192px;
    opacity: 1;
    transition: width 0.64s ease-out;
    z-index: 0;

    &-first-anim {
      left: 0;
      width: 200vw;
      transition: width 1.5s ease-out;
    }

    @media screen and (max-width: 1024px) {
      top: calc(50% - 96px);
    }
  }

  .faq-header {
    font-family: 'Gore', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 100%;
    margin: auto auto 49px auto;
    letter-spacing: 0.03em;
    color: $dark2;

    @media screen and (max-width: 1024px) {
      margin-left: 24px;
      font-weight: 900;
      font-size: 30px;
    }
  }

  .main-box {
    z-index: 1;
    background: $white;
    border-radius: 20px 20px 0px 0px;
    width: calc(100% - 200px);
    align-self: center;
    justify-self: right;
    display: flex;
    padding: 80px 100px;

    .why-dream {
      width: 50%;

      &-left {
        padding: 20px 20px;

        li {
          list-style-position: outside;
          font-family: 'Josefin Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 115%;
          color: $dark2;

          span {
            position: relative;
            left: 20px;
            top: -8px;
          }
        }

        p {
          font-family: 'Josefin Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 140%;
          color: $dark2;
          padding-left: 60px;
          padding-bottom: 60px;

          a {
            color: $blue3;
          }
        }
      }

      &-right {
        padding: 20px 25px;

        li {
          list-style-position: outside;
          font-family: 'Josefin Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 115%;
          color: $dark2;

          span {
            position: relative;
            left: 20px;
            top: -8px;
          }
        }

        p {
          font-family: 'Josefin Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 140%;
          color: $dark2;
          padding-left: 60px;
          padding-bottom: 60px;

          span {
            padding-left: 15px;
          }

          li {
            list-style-position: outside;
            font-family: 'Josefin Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            color: $dark2;
            padding-left: 42px;
          }

          a {
            color: $blue3;
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 10px 0;
      width: calc(100% - 48px);
      flex-direction: column;

      .why-dream {
        width: 100%;

        &-left {
          padding: 10px 30px 0 10px;

          li {
            font-weight: 700;
            font-size: 17px;
            line-height: 115%;

            span {
              position: relative;
              left: 10px;
              top: -12px;
            }
          }

          p {
            font-weight: 400;
            font-size: 14px;
            padding-top: 0;
            margin-top: 0;
            padding-left: 50px;
            padding-bottom: 20px;
          }
        }

        &-right {
          padding: 10px 30px 0 10px;

          li {
            font-weight: 700;
            font-size: 17px;
            line-height: 115%;

            span {
              position: relative;
              left: 10px;
              top: -12px;
            }
          }

          p {
            font-weight: 400;
            font-size: 14px;
            padding-left: 50px;
            padding-bottom: 20px;
            padding-top: 0;
            margin-top: 0;
            span {
              padding-left: 5px;
            }

            li {
              list-style-position: outside;
              font-family: 'Josefin Sans', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              color: $dark2;
              padding-left: 18px;
            }

            a {
              color: $blue3;
            }
          }
        }
      }
    }
  }

  .wake-up-text {
    font-family: 'Gore', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 152px;
    line-height: 80%;
    display: flex;
    align-items: center;
    justify-self: center;
    text-align: center;
    letter-spacing: 0.03em;
    color: $red1;
    opacity: 0.5;
    width: 90%;
    margin-left: 5%;
    margin-top: 14px;

    @media screen and (max-width: 1024px) {
      font-weight: 400;
      font-size: 33.2424px;
      line-height: 80%;
    }
  }

  .footer-box-home {
    z-index: 2;
    opacity: 0;
    bottom: 30px;
    width: 100%;
    height: 50px;
    position: absolute;
    overflow: hidden;

    &-first-anim {
      opacity: 1;
      bottom: 0;
      transition: all 0.64s ease-out;
    }

  }
}
