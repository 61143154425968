.page-home {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;

  .vertical-band {
    position: fixed;
    margin: auto;
    height: 115vh;
    width: 644px;
    opacity: 0;
    transition: opacity 0.64s ease-out, width 0.64s ease-out;
    transform-style: preserve-3d;

    &-first-anim {
      opacity: 1;
      transition: opacity 0.64s ease-out, width 0.64s ease-out;
    }

    &-second-anim {
      width: 809px;
      transition: opacity 0.64s ease-out, width 0.64s ease-out;
    }
  }

  .logo-box {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    position: relative;
    justify-content: center;
    align-content: center;
    opacity: 0;
    transition: opacity 0.64s ease-out;

    &-first-anim {
      opacity: 1;
      transition: opacity 0.64s ease-out;
    }

    .inside-logo-box {
      display: flex;
      flex-direction: column;
      align-self: center;
      width: 480px;
      max-width: 80vw;
      height: 100%;
      position: relative;
      transform-style: preserve-3d;
      transform: translateZ(40px);

      .middle-logo {
        position: absolute;
        margin: auto;
        align-self: center;

        @media screen and (min-width: 600px) {
          width: 368px;
          top: calc(50% - (270px / 2));
          left: calc((480px - 368px) / 2);
          transition: all 0.64s ease-out;

          @media screen and (max-height: 1081px) {
            width: 33vh;
            max-width: 33vh;
            top: calc(50% - (20vh / 2));
            left: calc((480px - 33vh) / 2);
            transition: all 0.64s ease-out;
          }
        }

        @media screen and (max-width: 600px) {
          width: 198px;
          top: 322px;
          left: calc((100% - 198px) / 2);
          transition: all 0.64s ease-out;
        }

        &-first-anim {
          transition: all 0.64s ease-out;

          @media screen and (min-width: 600px) {
            width: 272px;
            top: 91px;
            left: calc((480px - 272px) / 2);
            transition: all 0.64s ease-out;

            @media screen and (max-height: 1081px) {
              width: 22vh;
              max-width: 22vh;
              top: calc((50% - 38vh) / 2);
              left: calc((480px - 22vh) / 2);
              transition: all 0.64s ease-out;
            }
          }

          @media screen and (max-width: 600px) {
            width: 136px;
            top: 57px;
            left: calc((100% - 136px) / 2);
            transition: all 0.64s ease-out;
          }
        }
      }

      .circle-big {
        position: absolute;
        margin: auto;
        opacity: 0;
        transition: all 0.64s ease-out;

        @media screen and (min-width: 600px) {
          width: 480px;
          top: calc(50% - 330px);

          @media screen and (max-height: 1081px) {
            width: 44vh;
            max-width: 44vh;
            top: calc(50% - 25vh);
            left: calc((480px - 44vh) / 2);
            transition: all 0.64s ease-out;
          }
        }

        @media screen and (max-width: 600px) {
          top: 237px;
          width: 250px;
          left: calc((100% - 250px) / 2);

        }

        &-first-anim {
          opacity: 1;
          transition: all 0.64s ease-out;
        }

        &-second-anim {
          transition: all 0.64s ease-out;

          @media screen and (min-width: 600px) {
            width: 440px;
            top: -90px;
            left: calc((480px - 440px) / 2);

            @media screen and (max-height: 1081px) {
              width: 35vh;
              max-width: 35vh;
              top: calc(50% - 58vh);
              left: calc((480px - 35vh) / 2);
              transition: all 0.64s ease-out;
            }
          }

          @media screen and (max-width: 600px) {
            top: -30px;
            width: 226px;
            left: calc((100% - 226px) / 2);
          }
        }
      }

      .circle-small {
        position: absolute;
        margin: auto;
        align-self: center;
        max-width: 60vw;
        opacity: 0;
        transition: all 0.64s ease-out;

        @media screen and (min-width: 600px) {
          width: 280px;
          top: calc(50% - 84px);

          @media screen and (max-height: 1081px) {
            width: 22vh;
            top: calc(50% + 1vh);
            left: calc((480px - 22vh) / 2);
            transition: all 0.64s ease-out;
          }
        }

        @media screen and (max-width: 600px) {
          top: 368px;
          width: 141px;
          left: calc((100% - 141px) / 2);
        }

        &-first-anim {
          opacity: 1;
          transition: all 0.64s ease-out;
        }

        &-second-anim {
          transition: all 0.64s ease-out;

          @media screen and (min-width: 600px) {
            width: 230px;
            top: -120px;
            left: calc((480px - 230px) / 2);

            @media screen and (max-height: 1081px) {
              width: 20vh;
              max-width: 20vh;
              top: calc(50% - 62vh);
              left: calc((480px - 20vh) / 2);
              transition: all 0.64s ease-out;
            }
          }

          @media screen and (max-width: 600px) {
            top: -70px;
            width: 141px;
            left: calc((100% - 141px) / 2);
          }
        }
      }
    }

    .space-ship-box {
      position: absolute;
      margin: auto;
      align-self: center;
      opacity: 0;
      transition: all 0.64s ease-out;
      transform-style: preserve-3d;
      transform: translateZ(80px);

      @media screen and (min-width: 600px) {
        width: 85vw;
        max-width: 1920px;
        top: 30%;
        left: -38vw;

        @media screen and (max-height: 1081px) {
          width: 73vw;
          top: 28%;
          left: -29vw;
          transition: all 0.64s ease-out;

          img {
            max-height: 40vh;
          }
        }
      }

      @media screen and (max-width: 600px) {
        width: 105vw;
        top: 200px;
        right: -10vw;
      }

      &-first-anim {
        opacity: 1;
        transition: all 0.64s ease-out;

        @media screen and (min-width: 600px) {
          left: -35vw;
          top: 25%;

          @media screen and (max-height: 1081px) {
            width: 73vw;
            left: -25vw;
            top: 25%;
            transition: all 0.64s ease-out;
          }
        }

        @media screen and (max-width: 600px) {
          right: -10vw;
          top: 21%;

          @media screen and (max-height: 700px) {
            top: 25%;
          }
        }
      }

      .space-ship {
        transform: rotate(-15.73deg);
      }
    }
  }

  .parallax-effect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    transform-style: preserve-3d;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 809px;

    .inner-element {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transform: translateZ(80px);
    }
  }

  .wake-up-text {
    font-family: 'Gore', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.03em;
    position: absolute;
    color: $blue1;
    opacity: 0;
    height: 80px;
    padding: 0;
    margin: 0;

    @media screen and (min-width: 600px) {
      bottom: 11%;
      font-size: 40px;

      @media screen and (max-height: 1081px) {
        bottom: 10%;
        font-size: 36px;
      }
    }

    @media screen and (max-width: 600px) {
      bottom: 38%;
      top: unset;
      font-size: 32px;
    }

    @media screen and (max-width: 419px) {
      top: unset;
      bottom: 33%;
    }

    &-first-anim {
      opacity: 1;
      transition: all 0.64s ease-out;

      @media screen and (min-width: 600px) {
        bottom: 18%;

        @media screen and (max-height: 1081px) {
          bottom: 18%;
          font-size: 36px;
        }
      }

      @media screen and (max-width: 600px) {
        top: calc((105vw / 2.77) + 25% + 20px);
      }

      @media screen and (max-width: 419px) {
        top: calc((105vw / 2.77) + 25% + 20px);
      }
    }

  }

  .link-box {
    position: absolute;
    display: flex;
    z-index: 200;
    opacity: 0;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    align-self: center;

    #manifesto-btn {
      @media screen and (min-width: 600px) {
        margin-left: 50px;
      }


      @media screen and (max-width: 600px) {
        margin-top: 18px;
      }
    }

    #manifesto-btn-1 {
      @media screen and (min-width: 600px) {
        margin-left: 20px;
      }
    }

    @media screen and (min-width: 600px) {
      bottom: calc(8% + 50px + 20px);
    }

    @media screen and (max-width: 600px) {
      bottom: 14%;
      flex-direction: column;
    }

    @media screen and (max-width: 419px) {
      top: unset;
      bottom: 33%;
    }

    &-first-anim {
      opacity: 1;
      transition: all 0.64s ease-out;

      @media screen and (min-width: 600px) {
        bottom: 7%;

        @media screen and (max-height: 1081px) {
          bottom: 9%;
        }
      }

      @media screen and (max-width: 600px) {
        top: unset;
        bottom: calc(8% + 50px + 10px);
      }

      @media screen and (max-width: 419px) {
        top: unset;
        bottom: 18%;
      }
    }

    .link-btn {
      background: $red2;
      box-shadow: 10px 10px 20px rgba(2, 5, 7, 0.25);
      border-radius: 5px;
      font-family: 'Josefin Sans', sans-serif;
      font-style: normal;
      font-weight: 900;
      line-height: 150%;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      text-align: center;
      align-self: center;
      letter-spacing: 0.2em;
      color: $white;
      cursor: pointer;
      transition: all 0.2s ease-out;

      @media screen and (min-width: 600px) {
        width: 359px;
        height: 64px;
        font-size: 20px;

        @media screen and (max-height: 1081px) {
          width: 290px;
          height: 52px;
          font-size: 18px;
        }
      }

      @media screen and (max-width: 600px) {
        width: 233px;
        height: 45px;
        font-size: 15px;
      }

      &:hover {
        background: $white;
        color: $red2;
        font-size: 21px;
        transition: all 0.2s ease-out !important;
        box-shadow: 0 0 0 5px rgba(243, 235, 223, 0.2),
        0 0 0 10px rgba(243, 235, 223, 0.1);

        @media screen and (max-height: 1081px) {
          font-size: 19px;
          transition: all 0.2s ease-out !important;
        }
      }

      Link {
        display: flex;
        align-items: center;
        text-align: center;
        align-self: center;
      }
    }
  }

  .social-box {
    position: absolute;
    display: flex;
    z-index: 200;
    opacity: 0;

    @media screen and (min-width: 600px) {
      right: 4vw;
      top: 55px;
      flex-direction: column;

      a {
        margin-top: 25px;
      }
    }

    @media screen and (max-width: 600px) {
      left: calc((100% - 245px) / 2);
      bottom: 8%;
      flex-direction: row;

      a {
        margin-left: 25px;
      }
    }

    &-first-anim {
      opacity: 1;
      transition: all 0.64s ease-out;

      @media screen and (min-width: 600px) {
        right: 6vw;
      }

      @media screen and (max-width: 600px) {
        left: calc((100% - 245px) / 2);
        bottom: 7%;
        flex-direction: row;
      }
    }

    .social-btn {
      width: 50px;
      height: 50px;
      background: $white;
      border-radius: 50%;

      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      text-align: center;
      align-self: center;
      letter-spacing: 0.2em;
      color: $white;
      cursor: pointer;
      transition: all 0.2s ease-out;

      &:hover {
        svg {
          path {
            fill: $white !important;
          }

          g {
            path:nth-child(1) {
              fill: $white !important;
            }
            path:nth-child(2) {
              fill: $red2 !important;
            }
            path:nth-child(3) {
              fill: $red2 !important;
            }
            path:nth-child(4) {
              fill: $white !important;
            }
          }
        }

        background: $red2;
        box-shadow: 0 0 0 6px rgba(243, 235, 223, 0.2),
        0 0 0 12px rgba(243, 235, 223, 0.1);
        transition: all 0.2s ease-out;
      }

      a {
        display: flex;
        align-items: center;
        text-align: center;
        align-self: center;
      }
    }
  }

  .footer-box-home {
    opacity: 0;
    bottom: 30px;
    width: 100%;
    height: 50px;
    position: absolute;
    overflow: hidden;

    &-first-anim {
      opacity: 1;
      bottom: 0;
      transition: all 0.64s ease-out;
    }

  }

  .fogwrapper {
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
    filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
    z-index: 100;
  }

  #foglayer_01, #foglayer_02, #foglayer_03 {
    height: 100%;
    position: absolute;
    width: 200%;
  }
  #foglayer_01 .image01, #foglayer_01 .image02,
  #foglayer_02 .image01, #foglayer_02 .image02,
  #foglayer_03 .image01, #foglayer_03 .image02 {
    float: left;
    height: 100%;
    width: 50%;
  }
  #foglayer_01 {
    -webkit-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
    -moz-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
    animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  }
  #foglayer_02, #foglayer_03 {
    -webkit-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
    -moz-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
    animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  }

  /* ---------- Moving Fog ---------- */
  /*
    'size: cover' || 'size: 100%'; results remain the same
    'attachment: scroll' can be added or removed; results remain the same
    'attachment: fixed' causing unexpected results in Chrome
    'repeat-x' || 'no-repeat'; results remain the same
  */
  #foglayer_01 .image01, #foglayer_01 .image02 {
    background: url("../../../assets/imgs/fog1.png") center center/cover no-repeat transparent;
  }
  #foglayer_02 .image01, #foglayer_02 .image02,
  #foglayer_03 .image01, #foglayer_03 .image02{
    background: url("../../../assets/imgs/fog2.png") center center/cover no-repeat transparent;
  }

  /* ---------- Keyframe Layer 1 ---------- */
  @-webkit-keyframes foglayer_01_opacity {
    0% { opacity: .1; }
    22% { opacity: .5; }
    40% { opacity: .28; }
    58% { opacity: .4; }
    80% { opacity: .16; }
    100% { opacity: .1; }
  }
  @-moz-keyframes foglayer_01_opacity {
    0% { opacity: .1; }
    22% { opacity: .5; }
    40% { opacity: .28; }
    58% { opacity: .4; }
    80% { opacity: .16; }
    100% { opacity: .1; }
  }
  @-o-keyframes foglayer_01_opacity {
    0% { opacity: .1; }
    22% { opacity: .5; }
    40% { opacity: .28; }
    58% { opacity: .4; }
    80% { opacity: .16; }
    100% { opacity: .1; }
  }
  @keyframes foglayer_01_opacity {
    0% { opacity: .1; }
    22% { opacity: .5; }
    40% { opacity: .28; }
    58% { opacity: .4; }
    80% { opacity: .16; }
    100% { opacity: .1; }
  }
  /* ---------- Keyframe Layer 2 ---------- */
  @-webkit-keyframes foglayer_02_opacity {
    0% { opacity: .5; }
    25% { opacity: .2; }
    50% { opacity: .1; }
    80% { opacity: .3; }
    100% { opacity: .5; }
  }
  @-moz-keyframes foglayer_02_opacity {
    0% { opacity: .5; }
    25% { opacity: .2; }
    50% { opacity: .1; }
    80% { opacity: .3; }
    100% { opacity: .5; }
  }
  @-o-keyframes foglayer_02_opacity {
    0% { opacity: .5; }
    25% { opacity: .2; }
    50% { opacity: .1; }
    80% { opacity: .3; }
    100% { opacity: .5; }
  }
  @keyframes foglayer_02_opacity {
    0% { opacity: .5; }
    25% { opacity: .2; }
    50% { opacity: .1; }
    80% { opacity: .3; }
    100% { opacity: .5; }
  }
  /* ---------- Keyframe Layer 3 ---------- */
  @-webkit-keyframes foglayer_03_opacity {
    0% { opacity: .8 }
    27% { opacity: .2; }
    52% { opacity: .6; }
    68% { opacity: .3; }
    100% { opacity: .8; }
  }
  @-moz-keyframes foglayer_03_opacity {
    0% { opacity: .8 }
    27% { opacity: .2; }
    52% { opacity: .6; }
    68% { opacity: .3; }
    100% { opacity: .8; }
  }
  @-o-keyframes foglayer_03_opacity {
    0% { opacity: .8 }
    27% { opacity: .2; }
    52% { opacity: .6; }
    68% { opacity: .3; }
    100% { opacity: .8; }
  }
  @keyframes foglayer_03_opacity {
    0% { opacity: .8; }
    27% { opacity: .2; }
    52% { opacity: .6; }
    68% { opacity: .3; }
    100% { opacity: .8; }
  }
  /* ---------- Keyframe moveMe ---------- */
  @-webkit-keyframes foglayer_moveme {
    0% { left: 0; }
    100% { left: -100%; }
  }
  @-moz-keyframes foglayer_moveme {
    0% { left: 0; }
    100% { left: -100%; }
  }
  @-o-keyframes foglayer_moveme {
    0% { left: 0; }
    100% { left: -100%; }
  }
  @keyframes foglayer_moveme {
    0% { left: 0; }
    100% { left: -100%; }
  }

  @media only screen
  and (min-width: 280px)
  and (max-width: 767px) {
    #foglayer_01 .image01, #foglayer_01 .image02,
    #foglayer_02 .image01, #foglayer_02 .image02,
    #foglayer_03 .image01, #foglayer_03 .image02 {
      width: 100%;
    }
  }
}
